import { Fragment, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import { SchemaTimeLineLight } from "../../schemas/schemas.timeline";
import { SchemaPlaceLight } from "../../schemas/schemas.place";
import { SchemaLogLight } from "../../schemas/schema.log";
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CreateFormGroupText from "../../parts/components/minis/parts.minis.formPieces/parts.minis.textbox";
import CreateFormGroupArea from "../../parts/components/minis/parts.minis.formPieces/parts.minis.textarea";
import CreateFormGroupNumber from "../../parts/components/minis/parts.minis.formPieces/parts.minis.numberbox";
import CreateFormGroupReadOnly from "../../parts/components/minis/parts.minis.formPieces/parts.minis.readonly";
import CreateFormGroupDate from "../../parts/components/minis/parts.minis.formPieces/parts.minis.date";
import RoutesEditRpLogList from "./routes.editrp.loglist";

const RoutesAdminEditRpClass = () => {
    let { rpId } = useParams();
    const [rp, setRp] = useState([]);
    const { currentUser } = useContext(UserContext);
    const { mt } = useContext(UserContext);
    const [timeline, setTimeline] = useState([]);
    const [timelineFormFields, setTimelineFormFields] = useState(SchemaTimeLineLight);
    const { rps3DateTime, rps3CardText, rps3RoleplayKey, rps3PlaceKey, } = timelineFormFields;
    const [placeFormFields, setPlaceFormFields] = useState(SchemaPlaceLight);
    const { loc22Name, loc22Subtitle, loc22CardText, loc22Icon, } = placeFormFields;
    const [place, setPlace] = useState([]);
    const [logFormFields, setLogFormFields] = useState(SchemaLogLight);
    const {rps5TimelineKey, rps5CardText} = logFormFields;

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/roleplayDomain/V2/getLastTimelineForRP", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}&rid=${rpId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                if (users.status_code === 200) {
                    const lastTime = users.body[0].dateTime;
                    const placeKey = users.body[0].place;
                    const timelineId = users.body[0].id;
                    setTimelineFormFields({ ...timelineFormFields, rps3DateTime: lastTime, rps3RoleplayKey: rpId, rps3PlaceKey: placeKey });
                    setLogFormFields({ ...logFormFields, rps5TimelineKey: timelineId });
                }
                //console.log(users);
                //setRp(users.body);
            })


    }, [currentUser, rpId]);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/getThisSearch", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&lookupType=thisRp&test=0&aid=${uid}&rid=${rpId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                setRp(users.body);
                //setTimelineFormFields({ ...timelineFormFields, rps3RoleplayKey: rpId });
            })


    }, [currentUser, rpId]);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/roleplayDomain/V2/getTimelineForRP", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}&rid=${rpId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log(users);
                setTimeline(users.body);
            })


    }, [currentUser, rpId]);



    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/getPlaceInfo", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}`
        }
        )
            .then(response => response.json())
            .then(users => {
                setPlace(users.body);
            })


    }, [currentUser]);


    const handleTimelineChange = (event) => {
        const { name, value } = event.target;
        //console.log("Name: ", name, "Value: ", value);
        setTimelineFormFields({ ...timelineFormFields, [name]: value });
        //console.log(ideaFormFields);
    };

    const handlePlaceChange = (event) => {
        const { name, value } = event.target;
        //console.log("Name: ", name, "Value: ", value);
        setPlaceFormFields({ ...placeFormFields, [name]: value });
        //console.log(ideaFormFields);
    };

    const handleLogChange = (event) => {
        const { name, value } = event.target;
        //console.log("Name: ", name, "Value: ", value);
        setLogFormFields({ ...logFormFields, [name]: value });
        //console.log(ideaFormFields);
    };

    const handleNewTimelineSubmit = async (event) => {
        event.preventDefault();
        //console.log(ideaFormFields);

        try {
            let uid = -1;
            if (currentUser !== null) {
                uid = currentUser.uid;
            }
            //const serviceBody = `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&cha1First=${cha1First}&cha1Nick=${cha1Nick}&cha1Last=${cha1Last}&cha1Grade=${cha1Grade}&cha1PubertyS=${cha1PubertyS}&cha1PubertyE=${cha1PubertyE}&cha1BirthMonth=${cha1BirthMonth}&cha1BirthDay=${cha1BirthDay}&cha1Real=${cha1Real}&cha1Anime=${cha1Anime}&cha2Ageless=${cha2Ageless}&cha2AltName=${cha2AltName}&cha2CardText=${cha2CardText}&cha2Location=${cha2Location}&cha2Occupation=${cha2Occupation}&cha2Race=${cha2Race}&cha2Subtitle=${cha2Subtitle}&cha2Thumb=${cha2Thumb}`
            //console.log(serviceBody);
            fetch("https://api.liltiffyrps.com/roleplayDomain/V2/setNewTimeline", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&aid=${uid}&rps3DateTime=${rps3DateTime}&rps3CardText=${rps3CardText}&rps3RoleplayKey=${rps3RoleplayKey}&rps3PlaceKey=${rps3PlaceKey}`
            }
            )
                .then(response => response.json())
                .then(users => {
                    console.log(users)
                    switch (users.status_code) {
                        case 200:
                            //window.location.href = `/admin/edit/idea/${users.body.id}`;
                            window.location.reload();
                            break;
                        case 300:
                            //not found

                            break;

                        case 400:
                        //unauthorized

                    }

                })
            //const {user} = await signInAuthUserWithEmailandPassword(email, password);
            //resetFormFields();

        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };

    const handleNewPlaceSubmit = async (event) => {
        event.preventDefault();
        //console.log(ideaFormFields);

        try {
            let uid = -1;
            if (currentUser !== null) {
                uid = currentUser.uid;
            }
            //const serviceBody = `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&cha1First=${cha1First}&cha1Nick=${cha1Nick}&cha1Last=${cha1Last}&cha1Grade=${cha1Grade}&cha1PubertyS=${cha1PubertyS}&cha1PubertyE=${cha1PubertyE}&cha1BirthMonth=${cha1BirthMonth}&cha1BirthDay=${cha1BirthDay}&cha1Real=${cha1Real}&cha1Anime=${cha1Anime}&cha2Ageless=${cha2Ageless}&cha2AltName=${cha2AltName}&cha2CardText=${cha2CardText}&cha2Location=${cha2Location}&cha2Occupation=${cha2Occupation}&cha2Race=${cha2Race}&cha2Subtitle=${cha2Subtitle}&cha2Thumb=${cha2Thumb}`
            //console.log(serviceBody);
            fetch("https://api.liltiffyrps.com/locationDomain/V2/setNewPlace", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&aid=${uid}&loc22CardText=${loc22CardText}&loc22Subtitle=${loc22Subtitle}&loc22Icon=${loc22Icon}&loc22Name=${loc22Name}`
            }
            )
                .then(response => response.json())
                .then(users => {
                    console.log(users)
                    switch (users.status_code) {
                        case 200:
                            //window.location.href = `/admin/edit/idea/${users.body.id}`;
                            window.location.reload();
                            break;
                        case 300:
                            //not found

                            break;

                        case 400:
                        //unauthorized

                    }

                })
            //const {user} = await signInAuthUserWithEmailandPassword(email, password);
            //resetFormFields();

        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };

    const handleNewLogSubmit = async (event) => {
        event.preventDefault();
        //console.log(ideaFormFields);

        try {
            let uid = -1;
            if (currentUser !== null) {
                uid = currentUser.uid;
            }
            //const serviceBody = `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&cha1First=${cha1First}&cha1Nick=${cha1Nick}&cha1Last=${cha1Last}&cha1Grade=${cha1Grade}&cha1PubertyS=${cha1PubertyS}&cha1PubertyE=${cha1PubertyE}&cha1BirthMonth=${cha1BirthMonth}&cha1BirthDay=${cha1BirthDay}&cha1Real=${cha1Real}&cha1Anime=${cha1Anime}&cha2Ageless=${cha2Ageless}&cha2AltName=${cha2AltName}&cha2CardText=${cha2CardText}&cha2Location=${cha2Location}&cha2Occupation=${cha2Occupation}&cha2Race=${cha2Race}&cha2Subtitle=${cha2Subtitle}&cha2Thumb=${cha2Thumb}`
            //console.log(serviceBody);
            fetch("https://api.liltiffyrps.com/roleplayDomain/V2/setNewLog", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&aid=${uid}&rps5CardText=${rps5CardText}&rps5TimelineKey=${rps5TimelineKey}&rid=${rpId}`
            }
            )
                .then(response => response.json())
                .then(users => {
                    console.log(users)
                    switch (users.status_code) {
                        case 200:
                            //window.location.href = `/admin/edit/idea/${users.body.id}`;
                            window.location.reload();
                            break;
                        case 300:
                            //not found

                            break;

                        case 400:
                        //unauthorized

                    }

                })
            //const {user} = await signInAuthUserWithEmailandPassword(email, password);
            //resetFormFields();

        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };

    //console.log("Rp: ", rp);
    //console.log("Timeline: ", timeline);
    //console.log("TimelineFormFields: ", timelineFormFields);
    //console.log("PlaceFormFields: ", placeFormFields);
    //console.log("Place: ", place);
    //console.log("LogFormFields: ", logFormFields);

    return (
        <Fragment>
            {mt === "a" ?
                <Fragment>
                    {rp?.map((thisRp) => {
                        return (
                            <div key={thisRp.id} className="container" >
                                {/* Gallery + details*/}
                                <div className="bg-primary shadow-lg rounded-3 px-4 py-3 mb-5">
                                    <div className="px-lg-3">
                                        <h2 className="text-light text-center">Edit {thisRp.keys.uid}'s {thisRp.name.longName} Roleplay</h2>
                                        <p className="bg-danger text-light text-center"></p>
                                        <p className="bg-warning text-dark text-center"></p>

                                    </div>
                                </div>
                                <div className="bg-info shadow-lg rounded-3 px-4 py-3 mb-5">
                                    <div className="px-lg-3">
                                        < div className="row">
                                            <div>
                                                <Container>
                                                    <h3 className="text-center">RPS5: New Log Information</h3>
                                                    <p className="bg-danger text-light text-center"></p>
                                                    <Form onSubmit={handleNewLogSubmit}>
                                                        <CreateFormGroupArea
                                                            id="rps5CardText"
                                                            isRequired={false}
                                                            onChangeHandler={handleLogChange}
                                                            value={rps5CardText}
                                                            title="Log Description"
                                                            helpText="Please enter the log description."
                                                            rows="4" >
                                                        </CreateFormGroupArea>
                                                        
                                                        <Button variant="primary" type="submit">
                                                            Add To Log
                                                        </Button>
                                                    </Form>

                                                </Container>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="bg-info shadow-lg rounded-3 px-4 py-3 mb-5">
                                    <div className="px-lg-3">
                                        < div className="row">
                                            <div>
                                                <Container>
                                                    <h3 className="text-center">RPS3: New TimeLine Information</h3>
                                                    <p className="bg-danger text-light text-center"></p>
                                                    <Form onSubmit={handleNewTimelineSubmit}>
                                                        <CreateFormGroupArea
                                                            id="rps3CardText"
                                                            isRequired={false}
                                                            onChangeHandler={handleTimelineChange}
                                                            value={rps3CardText}
                                                            title="Timeline Description"
                                                            helpText="Please enter the long description of the scene."
                                                            rows="4" >
                                                        </CreateFormGroupArea>
                                                        <Form.Group className="mb-3" style={{ color: "black" }}>
                                                            <Form.Select onChange={handleTimelineChange} aria-label="Default select example" name="rps3PlaceKey" value={rps3PlaceKey}>
                                                                {place?.map((genre) => {
                                                                    return (
                                                                        <Fragment key={genre.id}>
                                                                            <option value={genre.id}>{genre.name}</option>
                                                                        </Fragment>
                                                                    )
                                                                })}
                                                            </Form.Select>
                                                        </Form.Group>
                                                        <CreateFormGroupDate
                                                            id="rps3DateTime"
                                                            isRequired={false}
                                                            onChangeHandler={handleTimelineChange}
                                                            value={rps3DateTime}
                                                            title="Date Time"
                                                            helpText="Please enter the Date Time for the start of the scene."
                                                            rows="4" >
                                                        </CreateFormGroupDate>

                                                        <Button variant="primary" type="submit">
                                                            Add To Timeline
                                                        </Button>
                                                    </Form>

                                                </Container>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="bg-info shadow-lg rounded-3 px-4 py-3 mb-5">
                                    <div className="px-lg-3">
                                        < div className="row">
                                            <div>
                                                <Container>
                                                    <h3 className="text-center">LOC22: New Place Information</h3>
                                                    <p className="bg-danger text-light text-center"></p>
                                                    <Form onSubmit={handleNewPlaceSubmit}>
                                                        <CreateFormGroupText
                                                            id="loc22Name"
                                                            isRequired={true}
                                                            onChangeHandler={handlePlaceChange}
                                                            value={loc22Name}
                                                            title="Place Name"
                                                            helpText="Please enter the name of the place." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="loc22Subtitle"
                                                            isRequired={false}
                                                            onChangeHandler={handlePlaceChange}
                                                            value={loc22Subtitle}
                                                            title="Place Short Description"
                                                            helpText="Please enter the short description of the place." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupArea
                                                            id="loc22CardText"
                                                            isRequired={false}
                                                            onChangeHandler={handlePlaceChange}
                                                            value={loc22CardText}
                                                            title="Place Long Description"
                                                            helpText="Please enter the long description of the place."
                                                            rows="4" >
                                                        </CreateFormGroupArea>
                                                        <CreateFormGroupText
                                                            id="loc22Icon"
                                                            isRequired={false}
                                                            onChangeHandler={handlePlaceChange}
                                                            value={loc22Icon}
                                                            title="Place Icon"
                                                            helpText="Please enter the icon of the place." >
                                                        </CreateFormGroupText>
                                                        <Button variant="primary" type="submit">
                                                            Add New Place
                                                        </Button>
                                                    </Form>

                                                </Container>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {timeline?.map((scene) => {
                                    return (
                                        <div key={scene.id}>
                                            <h3>Scene: {scene.cardText}</h3>
                                            <h4>Scene Start: {scene.dateTimeFormatted}</h4> 
                                            <h4>Scene Location: {scene.place}</h4>
                                            <hr /><br />
                                            <RoutesEditRpLogList timelineKey={scene.id}></RoutesEditRpLogList>
                                        </div>

                                    )
                                })}

                            </div>
                        )
                    })
                    }
                </Fragment>
                : ""}
        </Fragment>
    )
}

export default RoutesAdminEditRpClass;