import { Fragment, useState, useContext, useEffect } from "react";
import { UserContext } from "../../../contexts/user.context";
import RoutesEditRpLogList from "../../../routes/routes.editrp.class/routes.editrp.loglist";
import Accordion from 'react-bootstrap/Accordion';
import SharedComponentCard from "../../../shared/components/shared.components.card/shared.components.card.class";

const PartsRoleplayCast = ({ rpId }) => {
    const [castMembers, setCastMembers] = useState([]);
    const [castCount, setCastCount] = useState([]);
    const { currentUser } = useContext(UserContext);
    const { mt } = useContext(UserContext);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/modelDomain/V2/getModelsForRpSearch", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}&rid=${rpId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                console.log(users);
                setCastMembers(users.body);
                setCastCount(users.count);
            })


    }, [currentUser, rpId]);

    console.log(castMembers);
    let timeCount = 0;

    return (
        <div className="pt-3">
            <h2 className="h3 pt-2 pb-4 mb-4 text-center text-sm-start border-bottom">Cast<span className="badge bg-faded-accent fs-sm text-body align-middle ms-2">{castCount}</span></h2>
            <div className="row pt-2">
                {castMembers?.map((cast) => {
                    return (
                        <Fragment key={cast.id}>
                            <SharedComponentCard character={cast} page={"rp"}></SharedComponentCard>
                        </Fragment>
                    )
                })}
            </div>

        </div>
    )


}

export default PartsRoleplayCast;